import React from "react";

import Container from "../../components/Container/container";
import "./returns.scss";

const RefundLocation = () => {
	const domain =
		process.env.GATSBY_MYSHOPIFY_URL || "tearribles.myshopify.com";

	if (domain === "tearriblesau.myshopify.com") {
		return (
			<>
				<p>
					We accept returns within 30 days of receiving your order for
					products in unused, undamaged, re-sellable condition, with
					original tags still on the toy. Please contact tearribles at{" "}
					<a href="mailto:support@tearribles.com">
						support@tearribles.com
					</a>{" "}
					to get a return address.
				</p>
			</>
		);
	}

	return (
		<>
			<p>
				We accept returns within 30 days of receiving your order for
				products in unused, undamaged, re-sellable condition, with
				original tags still on the toy. Simply mail your return to:
			</p>
			<p style={{ paddingLeft: "2rem" }}>
				Tearrible Instincts
				<br />
				Attn: Returns (RMA *your order number*)
				<br />
				1400 Hamlin Ave Unit G
				<br />
				Saint Cloud FL 34771
			</p>
		</>
	);
};

const Returns = () => {
	return (
		<div className="returns">
			<Container style={{ maxWidth: "800px" }}>
				<h2>Returns and Exchanges</h2>
				<br />
				<p>
					We want you to be happy with your Tearribles experience, and
					have made it simple to return or exchange your items!
				</p>

				<h3>Refunds</h3>
				<RefundLocation />
				<p>
					Once your return is received and restocked into our
					inventory, we will issue a refund for the returned product
					back to your original method of payment.
				</p>
				<h3>Exchanges</h3>
				<p>
					We accept exchanges within 30 days of receiving your order,
					for products in unused, undamaged, re-sellable condition,
					with original tags still on the toy. If you'd like to
					exchange the Tearrible you purchased from our website for a
					different one, you can email us at{" "}
					<a href="mailto:support@tearribles.com">
						support@tearribles.com
					</a>{" "}
					and we will help you process the exchange.
				</p>
				<p>
					Please note that Tearrible Instincts only provides return
					labels in case of manufacturing defects, or shipping errors
					on our part.
				</p>
				<h3>Guarantee</h3>
				<p>
					We stand behind our products and cover any defects of
					fabric, stitching, squeakers, and hook & loop (aka velcro).
					Please note that Tearribles are not indestructible toys, and
					we do not guarantee our toys against damage caused by
					chewing, gnawing, or ripping of individual parts.
				</p>
				<p>
					We understand that mistakes happen so if your doggy damaged
					a part of the toy while they were learning how it works, we
					offer one time per toy style individual part replacements
					(subject to availability, including color) up to 30 days
					from the date of purchase.
				</p>
				<h3>Individual Part Replacements</h3>
				<p>
					If your Tearribles lose limbs, or suffer permanent
					dismemberment after 30 days, we offer low cost part
					replacements, just email us at{" "}
					<a href="mailto:support@tearribles.com">
						support@tearribles.com
					</a>{" "}
					and let us know what you're looking for.
				</p>
				<h3>Edible Products</h3>
				<p>
					At Tearrible Instincts, we strive to provide you with
					high-quality products and excellent service. Due to the
					nature of certain items in our inventory, we do not accept
					returns of edible chews, treats, or other food items.
				</p>
				<p style={{ fontWeight: "bold" }}>
					Why We Do Not Accept Returns for Edible Products:
				</p>
				<ol style={{ paddingLeft: "1.6rem" }}>
					<li>
						1. Safety and Health Concerns: Ensuring the safety and
						health of our customers and their pets is our top
						priority. Edible products have the potential to be
						tampered with or compromised once they leave our
						facility. For this reason, we cannot accept returns to
						guarantee the integrity of the items we provide.
					</li>
					<li>
						2. Quality Assurance: We maintain rigorous quality
						control standards throughout the manufacturing and
						handling of our edible products. Accepting returns of
						these items would compromise our ability to guarantee
						their freshness and safety.
					</li>
					<li>
						3. Health Regulations: In accordance with health
						regulations and safety guidelines, we cannot resell any
						product that has left our facility, ensuring that our
						customers receive only unopened and untampered goods.
					</li>
				</ol>
			</Container>
		</div>
	);
};

export default Returns;
