import React from "react";

import Returns from "../components/Returns/returns";

const ReturnsAndExchanges = () => (
	<div>
		<Returns />
	</div>
);

export default ReturnsAndExchanges;
